import ready from "@/scripts/ready";
import { teleportElement } from "@/scripts/utils";

const handleWidget = (el) => {
  const observer = new MutationObserver((records, observer) => {
    for (const record of records) {
      if (Array.from(record.removedNodes).find((i) => i.id === "Dsio-id1")) {
        observer.disconnect();
        const target = el.querySelector(".dsio-app");
        handleFilters(target, el);
      }
    }
  });

  observer.observe(el, { childList: true });
};

const handleFilters = (el, parent) => {
  let teleported = false;

  const observer = new MutationObserver((records, observer) => {
    for (const record of records) {
      const filtersVisible = record.target.classList.contains("moreFilters");

      if (filtersVisible && !teleported) {
        teleportElement(el, document.body);
        teleported = true;
      } else if (!filtersVisible && teleported) {
        teleportElement(el, parent);
        teleported = false;
      }
    }
  });

  observer.observe(el, { attributes: true, attributeFilter: ["class"] });
};

ready(".lae-widget", (el) => {
  if (window.innerWidth >= 768) return;
  handleWidget(el);
});
